import React from "react";
import FarmList from "./FarmList";

function Dashboard() {
  return (
    <>
      <FarmList></FarmList>
    </>
  );
}

export default Dashboard;
