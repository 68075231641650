import React from "react";

function Home() {
  return (
    <div className="container">
      <div className="page-title">
        <h1>Welcome to the World of Freedom! </h1>
        <h3>Your all round solution to agriculture is HERE!</h3>
      </div>
    </div>
  );
}

export default Home;
